<template>
  <div
    class="dropdown-select"
    :class="{ 'dropdown-select--open': isOpen }"
    :style="{ maxWidth }"
    v-click-outside="closeCustomSelect"
  >
    <div class="dropdown-select-input" :class="`dropdown-select-input--${size}`" @click="toggleCustomSelect">
      <div class="dropdown-select-input__label" :class="{ 'dropdown-select-input__label--placeholder': !labelText }">
        {{ labelText || placeholder }}
      </div>

      <div class="dropdown-select-input__icon">
        <atomic-icon id="arrow_expand-close" />
      </div>
    </div>

    <list-filterable-check-box
      v-if="isOpen"
      v-model:selectedOptions="selectedOptions"
      v-model:selectedOption="selectedOption"
      :options="options"
      :identifierKey="identifierKey"
      :labelKey="labelKey"
      :hideActionBtn="hideActionBtn"
      :multiple="multiple"
      :maxHeight="maxHeight"
      :size="size"
      @onSave="closeCustomSelect"
    />
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      options: any[];
      label?: string;
      identifierKey?: string;
      labelKey?: string;
      placeholder?: string;
      hideActionBtn?: boolean;
      multiple?: boolean;
      maxHeight?: string;
      maxWidth?: string;
      size?: 'default' | 'small';
    }>(),
    {
      options: () => [],
      identifierKey: 'identity',
      labelKey: 'name',
      hideActionBtn: false,
      multiple: false,
      maxHeight: '405px',
      size: 'default',
    }
  );

  const selectedOptions = defineModel<string[]>('selectedOptions', {
    default: () => [],
  });

  const selectedOption = defineModel<string | number>('selectedOption', {
    default: '',
  });

  const isOpen = ref(false);

  const labelText = computed(() => {
    if (!props.multiple) {
      const findItem = props.options.find(item => item[props.identifierKey] === selectedOption.value);
      return selectedOption.value ? findItem?.[props.labelKey] : props.label;
    }

    return selectedOptions.value.length
      ? props.options
          .filter(item => selectedOptions.value.includes(item[props.identifierKey]))
          .map(item => item[props.labelKey])
          .join(', ')
      : props.label;
  });

  const toggleCustomSelect = () => {
    isOpen.value = !isOpen.value;
  };

  const closeCustomSelect = () => {
    isOpen.value = false;
  };
</script>

<style src="~/assets/styles/components/selects/base/dropdown-select.scss" lang="scss" />
