<template>
  <div class="filterable-check-box" :style="{ maxHeight }">
    <div class="filterable-check-box__search" :class="`filterable-check-box__search--${size}`">
      <form-input-search
        v-model:value="searchValue"
        :placeholder="getContent(popupsData, defaultLocalePopupsData, 'gamesFilter.searchPlaceholder')"
      />
    </div>

    <template v-if="filteredOptions.length">
      <div class="filterable-check-box__options">
        <div
          class="filterable-check-box__option"
          :class="[
            `filterable-check-box__search--${size}`,
            { 'filterable-check-box__option--multiple': multiple },
            { 'filterable-check-box__option--active': isOptionSingleSelected(item[identifierKey]) },
          ]"
          v-for="item in filteredOptions"
          :key="item[identifierKey]"
          @click.stop="toggleOptionSelection(item[identifierKey])"
        >
          <form-input-checkbox
            :modelValue="isOptionSelected(item[identifierKey])"
            @change="toggleOptionSelection(item[identifierKey])"
            @click.stop
          >
            {{ item[labelKey] }}
          </form-input-checkbox>
        </div>
      </div>

      <div v-if="!hideActionBtn" class="filterable-check-box__btn">
        <button-base class="btn-apply" type="primary" size="sm" @click="onSave">
          {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.save') }}
        </button-base>
      </div>
    </template>

    <atomic-empty
      v-else
      :title="getContent(gamesContent?.currentLocaleData, gamesContent?.defaultLocaleData, 'empty.title')"
      :subTitle="getContent(gamesContent?.currentLocaleData, gamesContent?.defaultLocaleData, 'empty.description')"
      variant="not-found"
    />
  </div>
</template>

<script setup lang="ts">
  import { localSearch } from '@skeleton/utils/localSearchUtils';

  const props = withDefaults(
    defineProps<{
      options: any[];
      selectedOptions: string[];
      selectedOption: string | number;
      identifierKey?: string;
      labelKey?: string;
      hideActionBtn?: boolean;
      multiple?: boolean;
      maxHeight?: string;
      size?: 'default' | 'small';
    }>(),
    {
      options: () => [],
      selectedOptions: () => [],
      selectedOption: '',
      identifierKey: 'identity',
      labelKey: 'name',
      hideActionBtn: false,
      multiple: false,
      maxHeight: '400px',
      size: 'default',
    }
  );

  const emits = defineEmits<{
    (event: 'update:selectedOptions', value: string[]): void;
    (event: 'update:selectedOption', value: string | number): void;
    (event: 'onSave'): void;
  }>();

  const { getContent } = useProjectMethods();
  const gamesContentStore = useGamesContentStore();
  const { content: gamesContent } = storeToRefs(gamesContentStore);
  const { popupsData, defaultLocalePopupsData, layoutData, defaultLocaleLayoutData } = storeToRefs(useGlobalStore());

  const searchValue = ref('');

  const filteredOptions = computed(() => localSearch(props.options, searchValue.value, props.labelKey));

  const isOptionSelected = (value: string): boolean => {
    return props.selectedOptions.includes(value);
  };

  const isOptionSingleSelected = (value: string): boolean => {
    return !props.multiple && props.selectedOption === value;
  };

  const toggleOptionSelection = (value: string): void => {
    if (!props.multiple) {
      emits('update:selectedOption', value);
      onSave();
      return;
    }

    const updatedSelectedOptions = isOptionSelected(value)
      ? props.selectedOptions.filter(item => item !== value)
      : [...props.selectedOptions, value];

    emits('update:selectedOptions', updatedSelectedOptions);
  };

  const onSave = (): void => {
    emits('onSave');
  };
</script>

<style src="~/assets/styles/components/list/filterable-check-box.scss" lang="scss" />
