<template>
  <selects-base-bottom-sheet-select
    v-if="isMobile"
    v-model:selectedOptions="selectedOptions"
    v-model:selectedOption="selectedOption"
    :options="options"
    :title="title"
    :label="label"
    :placeholder="placeholder"
    :identifierKey="identifierKey"
    :labelKey="labelKey"
    :multiple="multiple"
    :showResetBtn="showResetBtn"
    :bottomSheetHeight="bottomSheetHeight"
    :bottomSheetMaxWidth="bottomSheetMaxWidth"
    :size="size"
    @onResetOptions="onResetOptions"
  >
    <template #bottom-sheet-activator="slotProps">
      <slot name="bottom-sheet-activator" v-bind="slotProps" />
    </template>

    <template #bottom-sheet-footer="slotProps">
      <slot name="bottom-sheet-footer" v-bind="slotProps" />
    </template>
  </selects-base-bottom-sheet-select>

  <selects-base-dropdown-select
    v-else
    v-model:selectedOptions="selectedOptions"
    v-model:selectedOption="selectedOption"
    :options="options"
    :label="label"
    :labelKey="labelKey"
    :placeholder="placeholder"
    :identifierKey="identifierKey"
    :hideActionBtn="hideActionBtn"
    :multiple="multiple"
    :maxHeight="maxHeight"
    :maxWidth="maxWidth"
    :size="size"
  />
</template>

<script setup lang="ts">
  withDefaults(
    defineProps<{
      options: any[];
      label?: string;
      identifierKey?: string;
      labelKey?: string;
      hideActionBtn?: boolean;
      multiple?: boolean;
      maxHeight?: string;
      maxWidth?: string;
      bottomSheetMaxWidth?: string;
      showResetBtn?: boolean;
      title?: string;
      placeholder?: string;
      bottomSheetHeight?: string;
      size?: 'default' | 'small';
    }>(),
    {
      options: () => [],
      identifierKey: 'identity',
      labelKey: 'name',
      hideActionBtn: false,
      multiple: false,
      showResetBtn: false,
      maxHeight: '405px',
      bottomSheetHeight: '70',
      size: 'default',
    }
  );

  const selectedOptions = defineModel<string[]>('selectedOptions', {
    default: () => [],
  });

  const selectedOption = defineModel<string | number>('selectedOption', {
    default: '',
  });

  const emits = defineEmits<{
    (event: 'onResetOptions'): void;
  }>();

  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const onResetOptions = () => {
    emits('onResetOptions');
  };
</script>
