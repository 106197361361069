<template>
  <div class="bottom-sheet-select" :style="{ maxWidth: bottomSheetMaxWidth }">
    <client-only>
      <slot name="bottom-sheet-activator" :onOpen="() => toggleFilter('open')">
        <div
          class="bottom-sheet-select-activator"
          :class="`bottom-sheet-select-activator--${size}`"
          @click="toggleFilter('open')"
        >
          <div
            class="bottom-sheet-select-activator__label"
            :class="{ 'bottom-sheet-select-activator__label--placeholder': !labelText }"
          >
            {{ labelText || placeholder }}
          </div>

          <div class="bottom-sheet-select-activator__icon">
            <atomic-icon id="arrow_expand-close" />
          </div>
        </div>
      </slot>

      <layout-bottom-sheet ref="bottomSheet" :height="bottomSheetHeight" disableMainSwipe>
        <template #header>
          <div class="bottom-sheet-select__header">
            <div v-if="title" class="bottom-sheet-select__title">
              {{ title }}
            </div>

            <div v-if="showResetBtn" class="bottom-sheet-select__reset" @click="onResetOptions">
              {{ getContent(popupsData, defaultLocalePopupsData, 'gamesFilter.reset') }}
            </div>
          </div>
        </template>

        <list-filterable-check-box
          v-model:selectedOptions="selectedOptions"
          v-model:selectedOption="selectedOption"
          :options="options"
          :identifierKey="identifierKey"
          :labelKey="labelKey"
          :multiple="multiple"
          maxHeight=""
          hideActionBtn
          @onSave="toggleFilter('close')"
        />

        <template #footer>
          <div class="bottom-sheet-select__buttons">
            <slot name="bottom-sheet-footer" :onClose="() => toggleFilter('close')">
              <button-base type="gray" size="md" @click="toggleFilter('close')">
                {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.close') }}
              </button-base>
            </slot>
          </div>
        </template>
      </layout-bottom-sheet>
    </client-only>
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      options: any[];
      title?: string;
      label?: string;
      placeholder?: string;
      identifierKey?: string;
      labelKey?: string;
      showResetBtn?: boolean;
      multiple?: boolean;
      bottomSheetHeight?: string;
      bottomSheetMaxWidth?: string;
      size?: 'default' | 'small';
    }>(),
    {
      options: () => [],
      identifierKey: 'identity',
      labelKey: 'name',
      showResetBtn: false,
      multiple: false,
      bottomSheetHeight: '70',
      size: 'default',
    }
  );

  const selectedOptions = defineModel<string[]>('selectedOptions', {
    default: () => [],
  });

  const selectedOption = defineModel<string | number>('selectedOption', {
    default: '',
  });

  const emits = defineEmits<{
    (event: 'onResetOptions'): void;
  }>();

  const { getContent } = useProjectMethods();
  const { popupsData, defaultLocalePopupsData, layoutData, defaultLocaleLayoutData } = storeToRefs(useGlobalStore());

  const bottomSheet = ref();

  const labelText = computed(() => {
    if (!props.multiple) {
      const findItem = props.options.find(item => item[props.identifierKey] === selectedOption.value);
      return selectedOption.value ? findItem?.[props.labelKey] : props.label;
    }

    return selectedOptions.value.length
      ? props.options
          .filter(item => selectedOptions.value.includes(item[props.identifierKey]))
          .map(item => item[props.labelKey])
          .join(', ')
      : props.label;
  });

  const onResetOptions = () => {
    emits('onResetOptions');
  };

  const toggleFilter = (methodName: 'open' | 'close') => {
    bottomSheet.value[methodName]();
  };
</script>

<style src="~/assets/styles/components/selects/base/bottom-sheet-select.scss" lang="scss" />
